import IcelandMap from "./gallery/IcelandMap";
import map from "./gallery/icelandMap.svg";
import logo from "../areas/logoAGH.png";

import {
  Svartifoss,
  Gullfoss,
  UnescoReykjanesGeopark,
  Valahnukur,
  Geysir,
} from "./gallery/Iceland";

export const arcticImages = {
  mapC: IcelandMap,
  map: map,
  logo: logo,
  photos: [
    { id: 0, src: Svartifoss, desc: "gallery_iceland_svartifoss" },
    { id: 1, src: Gullfoss, desc: "gallery_iceland_gullfoss" },
    { id: 2, src: UnescoReykjanesGeopark, desc: "gallery_iceland_geopark" },
    { id: 3, src: Valahnukur, desc: "gallery_iceland_cliff" },
    { id: 4, src: Geysir, desc: "gallery_iceland_geysir" },
  ],
};
