import { ITerritory } from "../../_helpers/models";
import IcelandMap from "../../_assets/areas/gallery/IcelandMap";
import { AGHWhite } from "../../_assets/logo";

export const arcticTerritoryIsland: ITerritory = {
  mapC: IcelandMap,
  name: "Iceland",
  slug: "iceland",
  description: "The land of fire and ice",
  routes: [
    {
      id: "iceland-1",
      name: "Surtshellir",
      videoId: "H8Ne5Fw4o6Y",
    },
    {
      id: "iceland-2",
      name: "Reykjanes Lighthouse",
      videoId: "Ja1yFgGNrsY",
    },
    {
      id: "iceland-2",
      name: "Valahnúkamöl",
      videoId: "x-ZKc7-9tIY",
    },
    {
      id: "iceland-3",
      name: "Gullfoss",
      videoId: "I_Rh06U3Hog",
    },
    {
      id: "iceland-4",
      name: "Geysir",
      videoId: "QFx1VLcZvho",
    },
    {
      id: "iceland-5",
      name: "Þingvellir (Thingvellir National Park)",
      videoId: "KpxImh5CcPA",
    },
    {
      id: "iceland-6",
      name: "Kerið",
      videoId: "-i0hH2LYD40",
    },
  ],
  info: {
    description:
      "Iceland is impressive not only with its severeness, but also with the amazing diversity of the landscape. In addition to spectacular places eagerly visited by tourists, you can also find areas free of anthropogenic sounds, where one can questr for silence.",
    partnerLogo: AGHWhite,
    partnerUrl: "https://www.agh.edu.pl/",
    linkMain: "Take a virtual journey using surround sound and 360 image.",
  },
};
