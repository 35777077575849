import React, { useState, useEffect } from "react";
import Sound from "react-sound";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";

interface IProps {
  url: string;
  autoplay?: boolean;
  stopPlaying?: boolean;
}

const PlayBtn = (props: IProps) => {
  const { url } = props;
  const [status, setStatus] = useState("STOPPED");

  useEffect(() => {
    console.log(props.stopPlaying);
    if(props.stopPlaying) {
      setStatus("STOPPED");
    }
  }, [props.stopPlaying])

  const onBtnClick = () => {
    if (status === "STOPPED") {
      return setStatus("PLAYING");
    }

    setStatus("STOPPED");
  };

  return (
    <div className="play-btn" onClick={onBtnClick}>
      <Sound
        url={url}
        playStatus={Sound.status[status]}
        onFinishedPlaying={() => setStatus("STOPPED")}
      />
      {status === "PLAYING" ? <StopIcon /> : <PlayArrowIcon />}
    </div>
  );
};

export default PlayBtn;
