import React from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@material-ui/icons/Close";
import { withStyles, Theme, DialogActions, Dialog } from "@material-ui/core";

import { vrIconMobile, vrInfoImg1, vrInfoImg2 } from "../../_assets/video";

export default function TerritoryMobileVRInfo() {
  const { t } = useTranslation();
  const [openOrientationInfo, setOrientationInfoOpen] = React.useState(true);
  const [openVRInfo, setVRInfoOpen] = React.useState(false);

  const VRInfoDialog = withStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(2),
      width: "100vw",
      height: "100vh",
    },
  }))(Dialog);

  const VRInfoOrientationDialog = withStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(2),
      width: "100vw",
      height: "100vh",
    },
  }))(Dialog);

  const handleOrienationInf = (): void => {
    setOrientationInfoOpen(false);
    setVRInfoOpen(true);
  };

  const handleVRnInf = (): void => {
    setVRInfoOpen(false);
  };

  return (
    <div className="VRMobileInfo">
      <VRInfoOrientationDialog
        open={openOrientationInfo}
        onClose={handleOrienationInf}
        aria-labelledby="responsive-dialog-title"
        className="VRMobileInfo__dialog"
        maxWidth={false}
      >

        <div className="VRMobileInfo__dialog-contentMobile">
          <img src={vrIconMobile} alt="vr" />

          <p>{t("vr_information_mobile")}</p>
          
          <div
            className="VRMobileInfo__dialog-contentMobile-button"
            onClick={handleOrienationInf}
          >
            <span>OK</span>
          </div>
        </div>
      </VRInfoOrientationDialog>

      <div className="VRMobileInfo__splash" >
        <p>{t("vr_information_mobile")}</p>
      </div>

      <VRInfoDialog
        open={openVRInfo}
        onClose={handleVRnInf}
        aria-labelledby="responsive-dialog-title"
        className="VRInfo__dialog"
        maxWidth={false}
      >
        <DialogActions>
          <div className="VRInfo__dialog__close" onClick={handleVRnInf}>
            <CloseIcon
              className="VRInfo__dialog__close-icon"
              style={{ fontSize: "28px" }}
            />
          </div>
        </DialogActions>

        <div className="VRInfo__dialog-content">
          <div className="VRInfo__dialog-content-element">
            <img src={vrInfoImg1} alt="VR Info" />
            <h2>Discover unique soundscape with VR Content</h2>
          </div>
          <div className="VRInfo__dialog-content-element">
            <img src={vrInfoImg2} alt="VR Info" />
            <p>
              Please use headphones for better sound experience. You can use
              your VR head set or just drag 360 image with your mouse. To
              navigate between VR Content please use context menu on a left side
              of the screen.
            </p>
          </div>

          <div className="VRInfo__dialog-content-button" onClick={handleVRnInf}>
            <span>{t("see_vr")}</span>
          </div>
        </div>
      </VRInfoDialog>
    </div>
  );
}
