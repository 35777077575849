import React from "react";

import { TerritoryPartners } from ".";

import { useTerritoryContext } from "../../context/Terrritory.context";

const TerritoryInfo = () => {
  const { territoryData } = useTerritoryContext();
  const { info } = territoryData;
  
  return (
    <div className="territoryInfo">
      <div className="territoryInfo__title">
        <h2>{territoryData.name}</h2>
        <h3>{territoryData.description}</h3>
      </div>
      <TerritoryPartners />

      <p className="territoryInfo__description">
        {info.description}
      </p>

      {info.quotation && (
        <p className="territoryInfo__description">
          <span className="territoryInfo__description-quotation">{info.quotation}</span>
        </p>
      )}

      {info.author && (
        <p className="territoryInfo__description">
          <span>{info.author}</span>{" "}
        </p>
      )}

      <p className="territoryInfo__linkMain">{info.linkMain}</p>

      {info.linkAdditional && (
        <p className="territoryInfo__linkAdditional">
          {info.linkAdditional}
        </p>
      )}

      <div className="territoryInfo__links">
        {info.links?.map((link) => (
          <>
            <a href={link.url}>{link.name}</a>
            <span>/</span>
          </>
        ))}
      </div>
    </div>
  );
};

export default TerritoryInfo;
