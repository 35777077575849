import React, { useEffect } from "react";
import Swiper from "swiper";
import { useTranslation } from "react-i18next";
import "swiper/css/swiper.css";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import {
  acoucou_logo,
  agh_logo,
  yi_logo,
  aes_logo,
  lh_logo,
} from "../../../_assets/logo";
interface IPartnerLogo {
  image: string;
  url: string;
}

const logos: IPartnerLogo[] = [
  { image: acoucou_logo, url: "https://acoucou.org" },
  { image: agh_logo, url: "http://kmiw.imir.agh.edu.pl" },
  { image: yi_logo, url: "http://sound2020.org" },
  { image: aes_logo, url: "http://www.aes.org" },
  { image: lh_logo, url: "http://ladne-halo.pl/" },
];

const Partners = () => {
  const { t } = useTranslation();

  useEffect(() => {
    new Swiper(".partners__slider", {
      slidesPerView: 4,
      spaceBetween: 0,
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 0,
        },

        800: {
          spaceBetween: 0,
          slidesPerView: 3,
        },

        600: {
          spaceBetween: 0,
          slidesPerView: 2,
        },

        100: {
          spaceBetween: 0,
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: ".partners__slider--next",
        prevEl: ".partners__slider--prev",
      },
      slideClass: "partners__slide",
      slideActiveClass: "partners__slide--active",
      wrapperClass: "partners__slider-wrapper",
      containerModifierClass: "partners__slider--",
      loop: true,
      autoplay: {
        delay: 3000,
      },
    });
  }, []);

  return (
    <section className="partners">
      <h2 className="partners__header">{t("our_partners")}</h2>

      <div className="partners__slider-container">
        <div className="partners__slider swiper-container">
          <div className="partners__slider-wrapper swiper-wrapper">
            {logos.map((logo: IPartnerLogo, key) => (
              <div key={key} className="partners__slide">
                <div className="partners__slide-logo">
                  <a href={logo.url} target="_blank" rel="noopener noreferrer">
                    <img src={logo.image} alt="Logo" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="slider__buttons slider__buttons--horizontal">
          <div className="partners__slider--prev slider__button slider__button--prev">
            <div className="slider__icon slider__icon--blue slider__icon--small">
              <ArrowBackIcon
                style={{
                  display: "block",
                  color: "#002850",
                  fontSize: "34px",
                }}
              />
            </div>
          </div>
          <div className="partners__slider--next slider__button slider__button--next">
            <div className="slider__icon slider__icon--blue slider__icon--small">
              <ArrowForwardIcon
                style={{
                  display: "block",
                  color: "#002850",
                  fontSize: "34px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
