import { IData } from "../_helpers/models";
import { arcticImages } from "../_assets/areas/index";
import { arcticTerritoryIsland } from "./areaPoints";

export const arcticData: IData = {

  typeOfArea: [
    {
      name: "area_generic_header",
      subname: "area_generic_subtitle",
      description: "teritory_arctic_descr",
      link: "area_generic_appendix",
      index: 0,
      locationX: "1000%",
      locationY: "-1000%",
      positionTab: "areaGlobe__informations-center"
    },
    {
      slug: "iceland",
      mapC: arcticImages.map,
      map: arcticImages.map,
      name: "teritory_arctic_iceland_name",
      subname: "teritory_arctic_iceland_subname",
      description: "teritory_arctic_iceland_descr",
      link: "area_generic_appendix",
      partnerLogo: arcticImages.logo,
      partnerUrl: "http://kmiw.imir.agh.edu.pl/",
      locationX: "46%",
      locationY: "42%",
      index: 1,
      territory: arcticTerritoryIsland,
      positionTab: "areaGlobe__informations-start"
    },
  ],

  regions: [
    {
      name: "teritory_arctic_iceland_name",
      subname: "teritory_arctic_iceland_subname",
      gallery: arcticImages.photos,
      index: 0,
    },
  ],

  panoramas: [],
};
