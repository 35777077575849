import React from "react";
import { useTranslation } from "react-i18next";

import { useTerritoryContext } from "../../context/Terrritory.context";

const TerritoryPartners = () => {
  const { territoryData } = useTerritoryContext();
  const { t } = useTranslation();

  return (
    <div className="territoryPartners">
      <span>{t("project_partners")}</span>
      <div className="territoryPartners__separator"></div>

      <div className="territoryPartners__element">
        <img src={territoryData.info.partnerLogo} alt="Academy of Acoustics" />
      </div>

      <div className="territoryPartners__separator"></div>

      <div className="territoryPartners__element">
        <a
          href={territoryData.info.partnerUrl}
          className="territoryPartners__element-button"
        >
          {t("see_website")}
        </a>
      </div>
    </div>
  );
};

export default TerritoryPartners;
