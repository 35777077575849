import React from "react";

import { useTerritoryContext } from "../../../context/Terrritory.context";

interface IProps {
  map: any;
}

const InteractiveMap = (props: IProps) => {
  const { territoryData, activeTerritoryRoute } = useTerritoryContext();

  return (
    <div className="interactiveMap">
      <props.map active={territoryData.routes[activeTerritoryRoute].id} />
    </div>
  );
};

export default InteractiveMap;
