import React, { useState, createContext, useContext } from "react";
import { ITerritory } from "../_helpers/models";
interface IProps {
  children: JSX.Element;
}

interface ITerritoryContext {
  territoryData: ITerritory;
  territoryToggles: ITerritoryToggles;
  activeTerritoryRoute: number;
}

interface ITerritoryActionsContext {
  setTerritoryData: Function;
  setActiveTerritoryRoute: Function;
  setTerritoryToggles: Function;
}

interface ITerritoryToggles {
  point: boolean;
  close: boolean;
  info: boolean;
}

const territoryTogglesInit: ITerritoryToggles = {
  point: false,
  close: false,
  info: false,
};

const territoryDataInit: ITerritory = {
  description: "",
  info: {
    description: "",
    partnerLogo: "",
    linkMain: "",
    linkAdditional: "",
    partnerUrl: "",
  },
  mapC: "",
  name: "",
  slug: "",
  routes: [
    {
      id: "",
      name: "",
    },
  ],
  extras: {
    description: "",
    image: "",
    sound: "",
    routeId: -1,
  },
};

const TerritoryContext = createContext<ITerritoryContext>({
  territoryData: territoryDataInit,
  territoryToggles: territoryTogglesInit,
  activeTerritoryRoute: 0,
});
const TerritoryActionsContext = createContext<ITerritoryActionsContext>({
  setTerritoryData: () => {},
  setActiveTerritoryRoute: () => {},
  setTerritoryToggles: () => {},
});

export const useTerritoryContext = () => useContext(TerritoryContext);
export const useTerritoryActionsContext = () =>
  useContext(TerritoryActionsContext);

export const TerritoryContextProvider = ({ children }: IProps) => {
  const [territoryData, setTerritoryData] = useState<ITerritory>(
    territoryDataInit
  );
  const [activeTerritoryRoute, setActiveTerritoryRoute] = useState<number>(0);
  const [territoryToggles, setTerritoryToggles] = useState<ITerritoryToggles>(
    territoryTogglesInit
  );
  return (
    <TerritoryContext.Provider
      value={{ territoryData, territoryToggles, activeTerritoryRoute }}
    >
      <TerritoryActionsContext.Provider
        value={{
          setTerritoryData,
          setTerritoryToggles,
          setActiveTerritoryRoute,
        }}
      >
        {children}
      </TerritoryActionsContext.Provider>
    </TerritoryContext.Provider>
  );
};
