import React, { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import CloseIcon from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { SliderTrails } from "./TerritoryPanelElements";

import pointImg from "../../../src/_assets/video/point.png";
import {
  useTerritoryContext,
  useTerritoryActionsContext,
} from "../../context/Terrritory.context";
import { IParams } from "../../_helpers/models";
import { useDesktop } from "../../hooks/useDesktop";

const TerritoryNavigation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { area } = useParams<IParams>();

  const {
    territoryData,
    territoryToggles,
    activeTerritoryRoute,
  } = useTerritoryContext();
  const { setTerritoryToggles } = useTerritoryActionsContext();
  const isDesktop = useDesktop();

  const [pinIconActive, setPinIconActive] = useState<boolean>(false);
  const [infoIconActive, setInfoIconActive] = useState<boolean>(false);
  const [isSliderTrails, setSliderTrails] = useState<boolean>(true);

  const pointClick = () => {
    setTerritoryToggles({ ...territoryToggles, point: true, info: false });
    setPinIconActive(true);
    setInfoIconActive(false);

    setSliderTrails(true);
  };

  const closeClick = () => {
    setTerritoryToggles({
      ...territoryToggles,
      close: !territoryToggles.close,
      point: !territoryToggles.point,
    });

    setPinIconActive(false);
    setInfoIconActive(false);
  };

  const infoClick = () => {
    setTerritoryToggles({ ...territoryToggles, info: true, point: true });
    setPinIconActive(false);
    setInfoIconActive(true);

    setSliderTrails(false);
  };

  return (
    <div
      className={classnames("videoNav", {
        videoNav__active: territoryToggles.point,
        videoNav: territoryToggles.info,
      })}
    >
      {isDesktop ? (
        <div
          className="videoNav__button"
          onClick={() => history.push(`/${area}`)}
        >
          <ArrowBackIcon className="videoNav__button-icon" />
          {t("back")}
        </div>
      ) : (
        <div
          className={classnames("videoNav__button", {
            videoNav__button: pinIconActive,
            "videoNav__button-hide": territoryToggles.point,
          })}
          onClick={() => history.push(`/${area}`)}
        >
          <ArrowBackIcon className="videoNav__button-icon" />
          {t("back")}
        </div>
      )}

      {/*PIN ICON*/}

      <div
        className={classnames("videoNav__action", {
          "videoNav--active": pinIconActive,
          "videoNav__action-clicked": territoryToggles.point,
        })}
        onClick={pointClick}
      >
        <LocationOnIcon
          className={classnames("videoNav__action-icon", {
            "videoNav__icon--active": pinIconActive,
            "videoNav__action-icon-clicked": territoryToggles.point,
          })}
        />
      </div>

      {/*INFO ICON*/}

      <div
        className={classnames("videoNav__action-info", {
          "videoNav--active": infoIconActive,
          "videoNav__action-info-clicked": territoryToggles.point,
        })}
        onClick={infoClick}
      >
        <div
          className={classnames("videoNav__action-info-icon", {
            "videoNav__icon--active": infoIconActive,
            "videoNav__action-info-icon-clicked": territoryToggles.point,
          })}
        >
          i
        </div>
      </div>

      <div
        className={classnames("videoNav__separator", {
          "videoNav__separator-clicked": territoryToggles.point,
        })}
      ></div>

      {/*Slider Trails */}

      {isDesktop ? (
        <>
          <img
            src={pointImg}
            alt="point"
            className={classnames("videoNav__image", {
              "videoNav__image-clicked": territoryToggles.point,
            })}
          />
          <div
            className={classnames("videoNav__route", {
              "videoNav__route-clicked": territoryToggles.point,
            })}
          >
            <p>
              {territoryData?.routes.length
                ? t(territoryData.routes[activeTerritoryRoute].name)
                : "Coming soon"}
            </p>
          </div>
        </>
      ) : (
        <>
          {isSliderTrails ? (
            <>
              <SliderTrails />

              {territoryToggles.point && (
                <div
                  className={classnames("videoNav__separator", {
                    "videoNav__separator-clicked": territoryToggles.point,
                  })}
                ></div>
              )}
            </>
          ) : (
            <div style={{ opacity: "0" }}>
              <SliderTrails />
            </div>
          )}
        </>
      )}

      {/*CLOSE ICON*/}

      <div
        className={classnames("videoNav__action-close", {
          "videoNav__action-close-clicked": territoryToggles.point,
        })}
        onClick={closeClick}
      >
        <CloseIcon
          className={classnames("videoNav__action-close-icon", {
            "videoNav__action-close-icon-clicked": territoryToggles.point,
          })}
        />
      </div>
    </div>
  );
};

export default TerritoryNavigation;
