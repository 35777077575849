import React from "react";
import ExtraModal from "../Extras/ExtraModal";

import { trails } from "./LegendData";

const Legend = () => {
  return (
    <div className="bigpanel__legend">
      <div>
        {trails.map((trail, key: number) => (
          <div key={key} className="bigpanel__legend-element">
            <img src={trail.image} alt={trail.name} />
            <p>{trail.name}</p>
          </div>
        ))}
      </div>
        <ExtraModal />
    </div>
  );
};

export default Legend;
