import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuOnPage } from "../Header";

import image from "../../_assets/contact.png";
import { appConfig } from "../../app.config";

interface Notification {
  type: "success" | "error";
  message: string;
}

const Contact = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [notification, setNotification] = useState<Notification>({
    type: "success",
    message: "",
  });

  const onFormChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    fetch(appConfig.API_URL, {
      method: "POST",
      body: data,
    })
      .then((response) => {
        if (response.ok) {
          setNotification({
            type: "success",
            message: "Your message was sent successfully",
          });
        } else {
          setNotification({
            type: "error",
            message: "Error. Please try again",
          });
        }
      })
      .catch(() => {
        setNotification({
          type: "error",
          message: "Error. Please try again",
        });
      });
  };

  return (
    <>
      <MenuOnPage />

      <section className="page">
        <div className="contact">
          <div className="contact__content">
            <img src={image} alt="contact" />
            <div className="contact__content-background"></div>
          </div>

          <div className="contact__content">
            <div className="contact__content-form">
              <h2>{t("contact_header")}</h2>
              <p>
                {t("contact_content")}
                <br />
                <a href="mailto:contact@embassyofsound.org">
                  contact@embassyofsound.org
                </a>
              </p>

              <form onSubmit={onSubmit}>
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    placeholder="name"
                    name="name"
                    onChange={onFormChange}
                    required
                  ></input>
                  <input
                    type="email"
                    placeholder="e-mail addresss"
                    name="email"
                    onChange={onFormChange}
                    required
                  ></input>
                </div>
                <textarea
                  className="contact__content-form-message"
                  placeholder="message"
                  name="message"
                  onChange={onFormChange}
                  required
                ></textarea>
                <span
                  className={classnames("form-notification", [
                    notification.type,
                  ])}
                >
                  {notification.message}
                </span>
                <button>{t("contact_send_message")}</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
