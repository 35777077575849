
import main from "../../../../_assets/video/trail_main.png";
import secondary from "../../../../_assets/video/trail_sec.png";
import drive from "../../../../_assets/video/trail_drive.png";

export interface ITrails {
  name: string;
  classMarking: string;
  image: string;
}

export const trails: ITrails[] = [
  {
    name: "main trekking route",
    classMarking:
      "bigpanel__legend-element-border bigpanel__legend-element-border--main",
    image: main,
  },
  {
    name: "secondary route",
    classMarking:
      "bigpanel__legend-element-borderbigpanel__legend-element-border--secondary",
    image: secondary,
  },
  {
    name: "drive route",
    classMarking:
      "bigpanel__legend-element-border bigpanel__legend-element-border--drive",
    image: drive,
  },
];